// Calculate notification times based on task deadline
const calculateDeadlineNotificationTimes = (created, deadline) => {
    const createdTime = new Date(created).getTime();
    const deadlineTime = new Date(deadline).getTime();
    const totalTime = deadlineTime - createdTime;

    // Calculate nearly due notification time as 10% remaining
    const nearlyDueTime = createdTime + (totalTime * 0.9);

    return {
        nearlyDue: new Date(nearlyDueTime),
        overdue: new Date(deadlineTime),
    };
};

export default calculateDeadlineNotificationTimes;
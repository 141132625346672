import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import dayjs from "dayjs";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React, {useState} from "react";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);
const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

const ScheduleContainer = ({ schedule, setSchedule}) => {
    const [error, setError] = useState(null);

    const onScheduleContainerClick = () => {
        if(!schedule) {
            setSchedule(true);
            const currentTime = dayjs();
            const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR ) * MILLISECONDS_IN_HOUR);
            setSchedule(roundedTime)
        }
    }

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'minDate':
            case 'minTime': {
                return 'You cannot schedule the task for the past';
            }

            case 'invalidDate': {
                return 'Please enter a valid date';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <div className="schedule-container" onClick={onScheduleContainerClick}>
            <FormControlLabel
                label=""
                sx={{mb: 1}}
                control={
                    <Checkbox
                        label="Schedule task"
                        checked={!!schedule}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            if (checked) {
                                const currentTime = dayjs();
                                const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR) * MILLISECONDS_IN_HOUR);
                                setSchedule(roundedTime)
                            } else {
                                setSchedule(null)
                            }
                        }}
                    />
                }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    minTime={schedule && schedule.isToday() ? dayjs() : null}
                    renderInput={(props) =>
                        <TextField {...props} helperText={errorMessage}/>}
                    label={schedule ? "Task scheduled for" : "Schedule task"}
                    disablePast={true}
                    value={schedule}
                    onChange={(newSchedule) => {
                        if (dayjs(newSchedule) < dayjs()) {
                            const currentTime = dayjs();
                            const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR) * MILLISECONDS_IN_HOUR);
                            setSchedule(roundedTime)
                        } else {
                            setSchedule(newSchedule);
                        }
                    }}
                    disabled={!schedule}
                    onError={(newError) => setError(newError)}
                    ampm={false}
                    inputFormat={"DD/MM/YYYY HH:mm"}
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                />
            </LocalizationProvider>
        </div>
    )
}

export default ScheduleContainer;
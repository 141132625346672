import {Preferences} from "@capacitor/preferences";
import {hashStringToInt} from "../helpers/hash";
import {LocalNotifications} from "@capacitor/local-notifications";

const scheduleScheduleNotificationsForTask = async (task) => {
    const scheduledTimeInMs = task.schedule * 1000;

    //Check if task already has notifications scheduled
    const existingNotifications = await Preferences.get({ key: `task_${task.taskId}_notifications_schedule` });
    if(existingNotifications.value) {
        return;
    }

    console.log("Scheduling schedule notifications for task:", task.taskId);
    const scheduledTime = new Date(scheduledTimeInMs);

    //Schedule notification
    const scheduleNotificationId = hashStringToInt(`${task.taskId}-schedule`);
    await LocalNotifications.schedule({
        notifications: [
            {
                title: "It's time to start your task",
                body: `"${task.name}" is scheduled to start now`,
                id:  scheduleNotificationId,
                schedule: { at: scheduledTime },
                extra: task.taskId
            }
        ]
    });
    console.log("Scheduled schedule notification for task:", task.taskId)

    // Update preferences with notification ids
    await Preferences.set({
        key: `task_${task.taskId}_notifications_schedule`,
        value: JSON.stringify({
            scheduleNotificationId
        }),
    });
}

export default scheduleScheduleNotificationsForTask;
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import dayjs from "dayjs";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React, {useState} from "react";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

const DeadlineContainer = ({ hasDeadline, setHasDeadline, setDeadline, deadline, isRecurring, setRecurring, setFrequency }) => {
    const [error, setError] = useState(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'minDate':
            case 'minTime': {
                return 'Your deadline cannot be in the past';
            }

            case 'invalidDate': {
                return 'Please enter a valid date';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    const onDeadlineContainerClick = () => {
        if(!hasDeadline) {
            setHasDeadline(true);
            const currentTime = dayjs();
            const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR ) * MILLISECONDS_IN_HOUR);
            setDeadline(roundedTime)

            if(isRecurring) {
                setRecurring(false)
            }
        }
    }

    return (
        <div className="deadline-container" onClick={onDeadlineContainerClick}>
            <FormControlLabel
                label=""
                sx={{mb: 1}}
                control={
                    <Checkbox
                        label="Deadline"
                        checked={!!hasDeadline}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            if (checked) {
                                const currentTime = dayjs();
                                const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR ) * MILLISECONDS_IN_HOUR);
                                setDeadline(roundedTime)
                                setFrequency(null);
                            } else {
                                setDeadline(null)
                            }

                            setHasDeadline(checked);
                        }}
                    />
                }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    minTime={deadline && deadline.isToday() ? dayjs() : null}
                    renderInput={(props) =>
                        <TextField {...props} helperText={errorMessage} />}
                    label={hasDeadline ? "Deadline" : "No deadline"}
                    disablePast={true}
                    value={deadline}
                    onChange={(newDeadline) => {
                        if (dayjs(newDeadline) < dayjs()) {
                            const currentTime = dayjs();
                            const roundedTime = dayjs(Math.ceil(currentTime.valueOf() / MILLISECONDS_IN_HOUR ) * MILLISECONDS_IN_HOUR);
                            setDeadline(roundedTime)
                        } else {
                            setDeadline(newDeadline);
                        }
                    }}
                    disabled={!hasDeadline}
                    onError={(newError) => setError(newError)}
                    ampm={false}
                    inputFormat={"DD/MM/YYYY HH:mm"}
                    views={['year', 'month', 'day', 'hours']}
                />
            </LocalizationProvider>
        </div>
    )
}

export default DeadlineContainer;
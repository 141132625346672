import {setLabelFilters, setMindset, setUserAuthenticated} from "../../redux/actions/user";
import { dispatch } from "../../redux/store";
import {setTasks} from "../../redux/actions/tasks";
import {setTaskLabels} from "../../redux/actions/taskLabels";
import {scheduleNotificationsForAllTasks} from "../../notifications/scheduleNotificationsForAllTasks";
import {Capacitor} from "@capacitor/core";
import {initialisePushNotifications} from "../../helpers/push-notifications";
import waitForLocalNotificationsPermission from "../../notifications/waitForLocalNotificationsPermission";
import cleanUpExpiredNotifications from "../../notifications/cleanupExpiredNotifications";

//Socket has been authenticated with the server
export default async (data) => {
    dispatch(setUserAuthenticated(true));

    if(data) {
        const { tasks, labels, mindset, filters } = data;
        dispatch(setTasks(tasks));
        dispatch(setTaskLabels(labels));
        dispatch(setMindset(mindset));
        dispatch(setLabelFilters(filters.labels));

        if(Capacitor.isNativePlatform()) {
            // TODO this needs splitting out somewhere, but has been put in a function to avoid blocking the main thread for now
            const notifications = async () => {
                initialisePushNotifications();
                await waitForLocalNotificationsPermission();
                await cleanUpExpiredNotifications(tasks);
                await scheduleNotificationsForAllTasks(tasks);
            }
            void notifications();
        }
    }
}
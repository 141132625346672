import {Checkbox, FormControlLabel, IconButton, Popover, TextField} from "@mui/material";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {useEffect, useMemo, useRef, useState} from "react";
import TaskFrequencySelector from "./TaskFrequencySelector";
import dayjs from "dayjs";
import {pluralise} from "../../../../helpers/text";

const RecurringTasksContainer = ({ frequency, isRecurring, setRecurring, hasDeadline, setHasDeadline, setDeadline, setFrequency }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const anchorRef = useRef(null);
    const popperRef = useRef(null);

    const recurringLabelText = useMemo(() => {
        if(isRecurring) {
            const { intervalCount, intervalDateType } = frequency;
            return `Every${intervalCount === 1 ? "" : ` ${intervalCount}`} ${pluralise(intervalDateType, intervalCount)}`;
        }
        return "Does not recur";
    }, [isRecurring, frequency]);

    useEffect(() => {
       if(popperRef.current) {
           popperRef.current.updatePosition();
       }
    });

    return (
        <div className="recurring-tasks-container">
            <FormControlLabel
                label=""
                sx={{mb: 1}}
                control={
                    <Checkbox
                        label="Recurring"
                        checked={!!isRecurring}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            setRecurring(checked)

                            setPopoverOpen(checked);
                            if(checked) {
                                setFrequency({
                                    startDate: dayjs().startOf("day").valueOf(),
                                    intervalCount: 1,
                                    intervalDateType: "week"
                                })
                            } else {
                                setFrequency(null);
                            }

                            if(hasDeadline) {
                                setHasDeadline(false)
                                setDeadline(null);
                            }
                        }}
                    />
                }
            />
            <TextField
                label="Recurs"
                ref={anchorRef}
                variant="outlined"
                disabled={!isRecurring}
                onClick={() => {
                    if(!isRecurring) return;
                    setPopoverOpen(popoverOpen => !popoverOpen)
                }}
                value={recurringLabelText}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton disabled={!isRecurring} className={"recurring-tasks-container_icon-button"}>
                            <EventRepeatIcon/>
                        </IconButton>
                    )
                }}
            />
            <Popover
                open={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                action={popperRef}
            >
                <TaskFrequencySelector frequency={frequency} setFrequency={setFrequency}/>
            </Popover>
        </div>
    );
}

export default RecurringTasksContainer;
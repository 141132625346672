import scheduleNotificationsForTask from "./scheduleNotificationsForTask";

export const scheduleNotificationsForAllTasks = async (tasks) => {
    try {
        console.log("Scheduling notifications for tasks with deadlines");
        // Get a list of tasks which have a deadline
        const tasksToSchedule = Object.values(tasks).filter(task => task.deadline);

        // Schedule notifications for tasks with deadlines
        tasksToSchedule.forEach(task => scheduleNotificationsForTask(task.taskId));
    }
    catch (e) {
        console.error(e);
    }
};
import store from "../redux/store";
import scheduleDeadlineNotificationsForTask from "./scheduleDeadlineNotificationsForTask";
import scheduleScheduleNotificationsForTask from "./scheduleScheduleNotificationsForTask";

const scheduleNotificationsForTask = async (taskId, createdTimestamp) => {
    //Get task definition from redux store
    const task = store.getState().tasks.taskSet[taskId];

    await scheduleDeadlineNotificationsForTask(task, createdTimestamp);
    await scheduleScheduleNotificationsForTask(task);
}

export default scheduleNotificationsForTask;